const APITypes = {
  IMS: 'Api-',
  LMS: 'ApiLMS-',
};

const Features = {
  Receipts: 'Receipt',
  Putaways: 'Putaway',
  Item: 'Item',
  Transfer: 'Transfer',
  Location: 'Location',
  Asset: 'Asset',
  Gateways: 'Gateways',
  Customer: 'Customer',
  Metadata: 'Metadata',
  Vendor: 'Vendor',
  Picking: 'PickList',
  CycleCount: 'CycleCount',
  Analytics: 'Analytics',
  Inventory: 'Inventory',
  Cockpit: 'Cockpit',
  Systems: 'Admin-System',
  Adjustments: 'Adjustment',
  Alerts: 'Alert',
  Settings: 'Settings',
  AfscCommandCenter: 'AfscCommandCenter',
  AlcThroughput: 'AlcThroughput',
  Users: 'User',
  Roles: 'Role',
  AAv: 'AAv',
  SRR: 'SRR',
  PDM: 'PDM',
  DMOR: 'DMOR',
  Parts: 'Parts',
  Electronics: 'Electronics',
  CncMachines: 'CncMachines',
  DataServices: 'DataServices',
};

export const FeatureTypes = () => {
  var result = {};
  Object.entries(Features).map(
    (key) => (result[key[0]] = `${APITypes['LMS']}${key[1]}`)
  );
  return result;
};

// const adminURL = () => {
//   switch (process.env.NODE_ENV) {
//     case 'development':
//       return 'https://sd-dev-adminui.azurewebsites.net/';
//     case 'qa':
//       return 'https://catamaran-uat-ims-adminui.sd.gov/';
//     case 'uat':
//       return 'https://catamaran-uat-ims-adminui.sd.gov/';
//     case 'production':
//       return 'https://catamaran-ims-adminui.sd.gov';
//   }
// };
export const SetFeaturesUrl = async (features) => {
  features.forEach((item) => {
    switch (item.id) {
      case FeatureTypes().Receipts:
        item['url'] = 'receipts';
        break;
      case FeatureTypes().Putaways:
        item['url'] = 'putaways';
        break;
      case FeatureTypes().Item:
        item['url'] = 'item-master';
        break;
      case FeatureTypes().Transfer:
        item['url'] = 'transfer-requests';
        break;
      case FeatureTypes().Location:
        item['url'] = 'location-master';
        break;
      case FeatureTypes().Asset:
        item['url'] = 'asset-master';
        break;
      case FeatureTypes().Gateways:
          item['url'] = 'gateways';
        break;
      case FeatureTypes().Customer:
        item['url'] = 'customer-master';
        break;
      case FeatureTypes().Metadata:
        item['url'] = 'metadata-master';
        break;
      case FeatureTypes().CycleCount:
        item['url'] = 'cycle-count';
        break;
      case FeatureTypes().Analytics:
        item['url'] = 'analytics';
        break;
      case FeatureTypes().Inventory:
        item['url'] = 'inventory-overview';
        break;
      case FeatureTypes().Vendor:
        item['url'] = 'vendor-master';
        break;
      case FeatureTypes().Cockpit:
        item['url'] = 'cockpit';
        break;
      case FeatureTypes().Picking:
        item['url'] = 'picking';
        break;
      case FeatureTypes().Adjustments:
        item['url'] = 'adjustments';
        break;
      case FeatureTypes().Roles:
        item['url'] = 'roles';
        break;
      case FeatureTypes().Users:
        item['url'] = 'users';
        break;
      case FeatureTypes().Settings:
        item['url'] = window.hasOwnProperty('REACT_APP_ADMIN_UI_LINK')
          ? window.REACT_APP_ADMIN_UI_LINK
          : process.env.REACT_APP_ADMIN_UI_LINK;
        break;
      case FeatureTypes().Alerts:
        // item['disabled'] = true;
        item['url'] = 'alerts';
        break;
      case FeatureTypes().AfscCommandCenter:
        item['url'] = 'analytics/8794b1a0-8d7d-45c4-b729-551262d1ab76';
        break;
      case FeatureTypes().AlcThroughput:
        item['url'] = 'analytics/8e80263b-1fd9-40a9-aa46-4916cd7a345d';
        break;
      case FeatureTypes().AAv:
        item['url'] = 'analytics/650434e8-adbc-4bf5-acb7-41ecb3562a4f';
        break;
      case FeatureTypes().SRR:
        item['url'] = 'analytics/96efd66a-1c3e-46ec-8805-2f24f5c06495';
        break;
      case FeatureTypes().PDM:
        item['url'] = 'analytics/7d07d62b-d7f2-4114-90fd-68ab94aa2073';
        break;
      case FeatureTypes().DMOR:
        item['url'] = 'analytics/848fc357-ebf0-4829-9eba-d6172fe010e4';
        break;
      case FeatureTypes().Parts:
        item['url'] = 'analytics/0d955631-53a7-4395-80d7-a85f06372937';
        break;
      case FeatureTypes().Electronics:
        item['url'] = 'analytics/098e481a-e1e6-4639-9d46-983ac4c1aead';
        break;
      case FeatureTypes().CncMachines:
        item['url'] = 'analytics/5b61247c-bf9a-4ee0-a273-6e6892b47fd0';
        break;
      case FeatureTypes().DataServices:
        item['url'] = 'analytics/1eb4118f-dbc3-434e-94a5-9be6d809194f';
        break;
      default:
        break;
    }

    if (item.submenu?.length) {
      SetFeaturesUrl(item.submenu);
    }
  });

  // return the features array after setting URL set for each feature
  return features;
};
